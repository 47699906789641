.vision {
  margin: 5rem auto;
  padding: 1rem;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.vision .container {
  width: 100%;
  justify-items: center;
  align-items: center;
  /* display: grid; */
  /* grid-template-columns: repeat(2, 1fr); */
  display: flex;
  justify-content: center;
  gap: 20px;
}

.vision .col-1 {
  cursor: pointer;
}
/*
.vision .col-1 p:nth-child(1) {
  font-size: 1.8rem;
  font-style: italic;
}

.vision .col-1 p:nth-child(2) {
  font-size: 3rem;
  font-weight: 600;
  margin: 0.5rem 0;
}

.vision .col-1 p:nth-child(3) {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
*/
.vision .col-2 {
  cursor: pointer;
}

@media screen and (max-width: 940px) {
  .vision .container {
    /* width: 100vw; */
    flex-direction: column;
    justify-content: space-around;
  }

  .vision img {
    width: 100%;
    height: auto;
  }
}
