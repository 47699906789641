.hero {
  background-color: white;
  color: #fff;
  height: 100vh;
  width: 100%;
  position: relative;
}
/* 
.hero:before {
  content: "";
  background: url("./images/flag.jpg") no-repeat center center/cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
} */
.hero h1 {
  color: #0f0f0f;
  font-size: 3rem;
  font-family: "logoFont";
  /* background: linear-gradient(to bottom, rgb(248, 246, 246), rgb(83, 52, 52));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(248, 247, 247);
  text-shadow: 0px 0px 7px #fcfcfc;
}
.hero .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1500px;
  margin: auto;
  padding: 10px;
  overflow: hidden;
  position: relative;
}
.hero .content span {
  color: #ffffff;
  font-family: BritannicBoldRegular;
  font-size: 3rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 1);
  text-shadow: 0px 0px 3px #000000;
}
.hero .innerContent {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20px;
  border-radius: 20px;
}
.hero .content p {
  font-size: 2rem;
  margin-top: 20px;
  text-shadow: 0px 0px 20px #0f0f0f;

  /* font-weight: 700; */
}
/* 
.hero .content p:nth-child(2) {
  font-size: 2.2rem;
  font-style: italic;
}

.hero .content p:nth-child(3) {
  font-size: 2.2rem;
  font-style: italic;
}

.hero .content p:nth-child(4) {
  font-size: 2.2rem;
  font-style: italic;
} */

.hero .button {
  margin: 2rem 0;
  width: max-content;
  margin-left: 200px;
}

@media only screen and (max-width: 550px) {
  .hero .button-group {
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0px;
  }
}

@media screen and (max-width: 940px) {
  /* .hero .content p:nth-child(3) {
    font-size: 1.5rem;
  } */

  /* .hero .content p:nth-child(4) {
    font-size: 1.6rem;
  } */
  .hero .content h1 {
    font-size: 2rem;
  }
  .hero .content p {
    font-size: 20px;
  }
  .hero .button {
    margin-left: 0;
  }
  .hero .content span {
    font-size: 2rem;
  }
  .hero .innerContent {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 7px;
    border-radius: 5px;
  }
  #hero-img {
    flex-direction: column;
    align-items: center;
  }
}
