@font-face {
  font-family: titleFont;
  src: url(./fonts/Acme-Regular.ttf);
}

.services {
  width: 100%;
}

.services .container {
  width: 100vw;
  margin: 4rem auto;
  padding: 4rem 0rem;
}

.services h2 {
  font-size: 3rem;
  text-align: center;
  font-family: titleFont;
  text-transform: uppercase;
}

.services .container .content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 4rem 0;
}

.services .card {
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgb(0, 0, 0, 0.2);
  padding: 1rem;
  cursor: pointer;
  height: 200px;
}
.services .card-front {
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgb(0, 0, 0, 0.2);
  padding: 1rem;
  cursor: pointer;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

/* .services img {
  border-radius: 50px;
  margin-top: -2rem;
} */

.services .card p {
  color: #787878;
  font-size: 1.1rem;
  margin: 12px;
  text-align: left;
}
.services .card-front h3 {
  text-align: center;
}

.services .card span {
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 700;
}

@media screen and (max-width: 940px) {
  .services .container {
    width: 100%;
  }

  .services .container .content {
    grid-template-columns: 1fr;
  }

  .services .card {
    max-width: 100%;
    margin: 1rem;
  }
  .services .card-front {
    max-width: 100%;
    margin: 1rem;
  }
}
