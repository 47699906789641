@font-face {
  font-family: titleFont;
  src: url(./fonts/Acme-Regular.ttf);
}

.demo {
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
}

.demo .container {
  width: 1240px;
  margin: auto;
  display: flex;
  justify-content: center;
}
.demo h2 {
  font-size: 3rem;
  text-align: center;
  font-family: titleFont;
  text-transform: uppercase;
}

.demo .col-1 {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.demo .col-1 p:nth-child(1) {
  font-size: 1.8rem;
  font-style: italic;
}

.demo .col-1 p:nth-child(2) {
  font-size: 3rem;
  font-weight: 600;
  margin: 0.5rem 0;
}

.demo .col-1 p:nth-child(3) {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.demo .col-2 {
  margin: auto;
}

@media screen and (max-width: 940px) {
  .demo .container {
    max-width: 100%;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }

  .demo iframe {
    width: 100%;
    height: auto;
  }
}

.formInput {
  display: flex;
  flex-direction: column;
}

input {
  padding: 5px;
  margin: 10px 0px;
  border-radius: 5px;
  border: 1px solid gray;
  caret-color: black;
  width: 100%;
  font-size: 15px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

label {
  font-size: 20px;
  color: gray;
}

.error {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
}

input:invalid[focused="true"] {
  border: 1px solid red;
}

input:invalid[focused="true"] ~ span {
  display: block;
}

form {
  background-color: white;
  padding: 0px 60px;
  border-radius: 10px;
}
textarea {
  caret-color: black;
  padding: 15px;
  margin: 10px 0px;
  border-radius: 5px;
  border: 1px solid gray;
  font-size: 15px;
}

textarea:invalid[focused="true"] {
  border: 1px solid red;
}

textarea:invalid[focused="true"] ~ span {
  display: block;
}
#form-container {
  display: flex;
  justify-content: center;
  width: 100%;
  /* max-width: 600px; */
}

#form {
  max-width: 600px;
  padding: 5%;
  min-width: 300px;
  box-sizing: content-box;
}
