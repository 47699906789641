.footer {
  background-color: #000000;
  color: #ffffff;
  padding: 3rem 1rem;
  margin-top: 10px;
}

.footer .container {
  max-width: 1240px;

  margin: auto;
  text-align: center;
}

.footer ul {
  display: flex;
  justify-content: center;
}

.footer li {
  padding: 1rem;
}

.footer li a {
  color: #ffcd00;
  cursor: pointer;
}

.footer li a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
}

.bottom {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding-top: 3rem;
}

.line {
  margin-bottom: 1rem;
}

@media screen and (max-width: 500px) {
  .footer li {
    padding: 10px;
  }
  .privacy {
    flex-direction: column;
    justify-content: space-around;
    height: 200px;
  }
  .footer .container {
    width: 100vw;
  }
}
@media screen and (max-width: 300px) {
  .footer li {
    display: none;
  }
}
