@font-face {
  font-family: titleFont;
  src: url(./fonts/Acme-Regular.ttf);
}

.about {
  background-color: #f8f8f8;
  margin: auto;
  padding: 8rem 1rem 0 1rem;
  width: 100%;
}

.about .container {
  width: 1240px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.about h2 {
  font-size: 3rem;
  font-family: titleFont;
  text-transform: uppercase;
}

.about .col-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.about .col-2 p:first-of-type {
  margin: 2rem 0;
}

.about .col-2 {
  font-size: 1.8rem;
  font-style: italic;
  font-weight: 600;
}

.about button {
  margin-top: 1rem;
}
.about img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 940px) {
  .about .container {
    grid-template-columns: 1fr;
  }

  .about .container {
    width: 100%;
  }

  .about .container img {
    padding: 0rem;
    width: 90vw;
  }

  .about .container .col-2 {
    padding: 0;
  }

  .about button {
    margin-bottom: 1rem;
  }
}
