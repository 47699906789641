.legal {
  background-color: white;
  /* color: #fff; */
  height: 100%;
  width: 100%;
  position: relative;
  /* font-size: clamp(1.5rem, 2vw, 1.75rem); */
}

.legal h1 {
  font-size: 3rem;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-decoration: underline;
  text-underline-offset: 50;
}

.legal h2 {
  font-size: 2rem;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-decoration: underline;
  text-underline-offset: 50;
}

.legal h3 {
  font-size: 1.5rem;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-decoration: underline;
  text-underline-offset: 50;
  margin-bottom: 10px;
}

.legal section {
  width: 90%;
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 25px;
}

.legal section h2 {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.legal p {
  animation: fade linear both;
  animation-timeline: view();
  animation-range: entry 50% cover 50%;
}
#google {
  animation: fade linear both;
  animation-timeline: view();
  animation-range: entry 50% cover 50%;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.legal ul {
  list-style: none;
  padding: 0;
}

.legal ul li {
  display: flex;
  align-items: center;
}

.legal ul li::before {
  content: "•";
  color: black;
  font-size: 1.5em;
  margin-right: 0.5em;
}
