.header {
  position: fixed;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
}

.nav-bg {
  background-color: rgba(0, 0, 0, 0.7);
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1500px;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}

.header .nav-menu a {
  color: #faf7f7;
  cursor: pointer;
}

.header .navbar img {
  width: 500px;
  height: auto;
  cursor: pointer;
  object-fit: cover;
  padding: 50px;
}

.header .nav-menu {
  display: flex;
}

.header .nav-item {
  padding: 1rem;
  font-weight: 700;
}

.header .nav-item a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
  display: none;
}

@media screen and (max-width: 940px) {
  .header {
    max-width: 100%;
    /* background-color: rgba(0, 0, 0, 0.9); */
    height: 90px;
  }

  .header .navbar {
    max-width: 100%;
  }

  .hamburger {
    display: block;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 90px;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 90vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }

  .nav-menu.active {
    left: 0;
  }

  /* .nav-item {
    margin: 1rem 0;
  } */

  .header .navbar img {
    width: 300px;
    padding: 0px;
    margin: 0px;
  }
  .phones {
    display: none;
  }
}
