@font-face {
  font-family: titleFont;
  src: url(../components/fonts/Acme-Regular.ttf);
}
#content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
}

.about-us {
  background-color: #f8f8f8;
  padding: 2rem 1rem 0 1rem;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.container {
  margin: 0 auto;
}

.about-us .container {
  width: 1240px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.about-us h2 {
  font-size: 3rem;
  font-family: titleFont;
  text-transform: uppercase;
}

.about-us .col-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.about-us .col-2 p:first-of-type {
  margin: 2rem 0;
}

.about-us .col-2 {
  font-size: 1.8rem;
  font-style: italic;
  font-weight: 600;
}

.about-us button {
  margin-top: 1rem;
}
.about-us img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 940px) {
  #content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
  }
  .about-us .container {
    grid-template-columns: 1fr;
  }

  .about-us .container {
    width: 100%;
  }

  .about-us .container img {
    padding: 0rem;
    width: 950;
  }

  .about-us .container .col-2 {
    padding: 0;
  }

  .about-us button {
    margin-bottom: 1rem;
  }
}

.item {
  width: inherit;
  height: inherit;
  background: white;
  transform-origin: 50% 100%;
}
.animation-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100vh; */
  overflow: hidden; /* Ensures the object is hidden once it moves out of the viewport */
}
#network {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
