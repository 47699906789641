#error {
  margin-top: 200px;
}
#error h1 {
  font-family: "Helvetica Neue", "Segoe UI", Segoe, Helvetica, Arial,
    "Lucida Grande", sans-serif;
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-align: center;
}
.container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 177px;
  max-width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
}

#error .row:before,
.row:after {
  display: table;
  content: " ";
}

#error .col-md-6 {
  width: 50%;
}

#error .col-md-push-3 {
  margin-left: 25%;
}

#error h1 {
  font-size: 48px;
  font-weight: 300;
  margin: 0 0 20px 0;
}

#error .lead {
  font-size: 21px;
  font-weight: 200;
  margin-bottom: 20px;
  font-family: "Helvetica Neue", "Segoe UI", Segoe, Helvetica, Arial,
    "Lucida Grande", sans-serif;
  font-weight: normal;
  margin: 0;
  padding: 0;
  text-align: center;
}

#error p {
  margin: 0 0 10px;
}

#error a {
  color: #3282e6;
  text-decoration: none;
}
#error .header {
  position: fixed;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s ease-in;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
}

#error .nav-bg {
  background-color: rgba(0, 0, 0, 0.7);
}

#error .header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}

#error .header .nav-menu a {
  color: #faf7f7;
  cursor: pointer;
}

#error .header .navbar img {
  width: 250px;
  height: auto;
  cursor: pointer;
  object-fit: fill;
  padding: 30px;
}

#error .header .nav-menu {
  display: flex;
}

#error .header .nav-item {
  padding: 1rem;
  font-weight: 700;
}

#error .header .nav-item a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
}

#error .hamburger {
  display: none;
}

@media screen and (max-width: 940px) {
  #error .header {
    max-width: 100%;
    /* background-color: rgba(0, 0, 0, 0.9); */
    height: 90px;
  }

  #error.header .navbar {
    max-width: 100%;
  }

  #error .hamburger {
    display: block;
  }

  #error .nav-menu {
    position: fixed;
    left: -100%;
    top: 90px;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 90vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
  }

  #error .nav-menu.active {
    left: 0;
  }

  #error .nav-item {
    margin: 1.5rem 0;
  }

  #error .header .navbar img {
    width: 200px;
    padding: 0px;
    margin: 0px;
  }
}
