@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "aboutFont";
  src: local("aboutFont"),
    url("./components/fonts/YoungSerif-Regular.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "BritannicBoldRegular";
  src: local("aboutFont"),
    url("./components/fonts/BritannicBoldRegular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "logoFont";
  src: local("logoFont"),
    url("./components/fonts/DelaGothicOne-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Acme-Regular";
  src: local("Acme-Regular"),
    url("./components/fonts/Acme-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "LilitaOne-Regular";
  src: local("LilitaOne-Regular"),
    url("./components/fonts/LilitaOne-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./components/fonts/Montserrat.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Pacifico-Regular";
  src: local("Pacifico-Regular"),
    url("./components/fonts/Pacifico-Regular.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Robot";
  src: local("Robot"), url("./components/fonts/Robot.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "YoungSerif-Regular";
  src: local("YoungSerif-Regular"),
    url("./components/fonts/YoungSerif-Regular.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "DelaGothicOne-Regular";
  src: local("DelaGothicOne-Regular"),
    url("./components/fonts/DelaGothicOne-Regular.ttf") format("truetype");
  font-weight: bold;
}

:root {
  --primary-color: #5bb462;
  --primary-dark: #4f9c55;
  --secondary-color: #ffea00;
  --secondary-dark: #e3d002;
  --overlay: rgba(0, 0, 0, 0.7);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  caret-color: transparent;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.button {
  padding: 17px 28px;
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  background-color: rgb(0, 0, 0);
  border: 1px solid #000000;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.line {
  display: block;
  width: 40px;
  height: 3px;
  margin: 5px auto;
  background-color: var(--primary-color);
}
@media only screen and (max-width: 400px) {
  #app-container {
    margin-top: 100px;
  }
}
